export class SocialLoginService {

    // Google SignIn
    // (can only be tested with https://localhost:8080
    // because our subdomain https://fleuropwebshop.localhost
    // is not accepted by google!)
    
    constructor() {
        this.googleSocialProcess = 'login';
    };

    handleGoogleCredentialResponse(response) {
        try {
            var jsonResponse = JSON.parse(atob(response.credential.split('.')[1]));

            if (SocialLoginService.googleSocialProcess == 'register') {
                location.href = dynamicData.page.virtualRoot +
                    'Registration.aspx?m=' + jsonResponse.email + 
                    '&f=' + jsonResponse.given_name + 
                    '&l=' + jsonResponse.family_name;
            } else {
                let socialLoginService = new SocialLoginService();
                socialLoginService.loginSocialMedia(jsonResponse.email, jsonResponse.jti, response.credential, 'Google');
            };
        
            dataLayer.push({ 'event': 'loginSocial', 'loginProvider': 'Google' });
            console.log('Google SignIn complete');
        }
        catch(err) {
            console.log('Google SignIn failed');
        }
    };

    signOut_Google() {
        //Google no longer allows to logout from himself ;-)
    };


    // Facebook SignIn
    attachFacebook(element) {
        FB.login(function (response) {
            FB.getLoginStatus(function(response) {
                if (response.status === 'connected') {
                    var id_token = response.authResponse.accessToken;
                }

                if (response.authResponse) {
                    FB.api('/me', { fields: 'id, name, email, first_name, last_name' }, function (response) {
                        if (element == 'register-facebook') {
                            location.href = dynamicData.page.virtualRoot + 'Registration.aspx?m=' + response.email + '&f=' + response.first_name + '&l=' + response.last_name;
                        } else {
                            let socialLoginService = new SocialLoginService();
                            socialLoginService.loginSocialMedia(response.email, response.id, id_token, 'Facebook');
                        };  

                        dataLayer.push({ 'event': 'loginSocial', 'loginProvider': 'Facebook' });
                        console.log('Facebook SignIn complete');
                    });
                } else {
                    console.log('Facebook SignIn failed');
                }
            });
        }, { scope: 'email' });
    };

    signOut_Facebook() {
        FB.getLoginStatus(function (response) {
            FB.logout(function (response) {
                console.log('Facebook SignOut complete');
            });
        });
    };


    // Login to MyFleurop (with Google or Facebook)
    loginSocialMedia(userLogin, socialMediaID, tokenID, socialMediaType) { 
        let socialLoginService = new SocialLoginService();  

        let profileService = new FleuropWebShop.ProfileService();
        profileService.LoginSocialMedia(userLogin, socialMediaID, tokenID, socialMediaType, function (result) {
            socialLoginService.clearLoginAlert();
            
            if (result == dynamicData.enums.enmMyFleuropLoginStatus.Success) {
                $('#user-popup').modal('hide');
                if (dynamicData.page.codeFileName == 'orderform') {
                    $('#txtSenderPassword:visible').val('');
                    angularOrderFormController.profile.getLoginState();
                    socialLoginService.toggleLoginState();
                    dynamicData.session.loginStateIsLoggedIn = true;
                    FleuropWebShop.ProfileService.GetGreeting(function (result) {
                        $('#divLoggedIn span').html(result);
                    })
                } else {
                    location.href = dynamicData.page.virtualRoot + 'myfleurop.aspx';
                }
            } else if (result == dynamicData.enums.enmMyFleuropLoginStatus.Failed) {
                socialLoginService.setLoginAlert(dynamicData.textRepository.loginError, 'alert-danger');
                gtdl_push(3);
            } else if (result == dynamicData.enums.enmMyFleuropLoginStatus.NotVerified) {
                socialLoginService.setLoginAlert(dynamicData.textRepository.loginNotVerifiedError, 'alert-warning');
                $('.loginAlertPlaceholder a').prop("href", dynamicData.page.virtualRoot + 'handlers/emailverification.aspx?m=' + email);
                gtdl_push(14);
            } else if (result == dynamicData.enums.enmMyFleuropLoginStatus.NoPassword) {
                //Send pw reset mail            
                FleuropWebShop.ProfileService.ResetPassword(email);
                socialLoginService.setLoginAlert(dynamicData.textRepository.loginNoPasswordError, 'alert-warning');
            }
        });
    };


    // Help-functions
    clearLoginAlert() {
        $('.loginAlertPlaceholder').hide();
    };

    toggleLoginState() {
        $('#divLoggedIn').toggleClass('d-none');
        $('#divLoggedOut').toggleClass('d-none');
    };

    setLoginAlert(message, alertClass) {
        var loginDiv = $('.loginAlertPlaceholder div');
        loginDiv.prop('class', 'alert ' + alertClass);
        loginDiv.html(message);

        $('.loginAlertPlaceholder').show();
    };

};