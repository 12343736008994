export function initializeProductImages() {
    configureImageRollover();
    configureImageRolloverSearchSite();
}

function configureImageRollover() {
    $('.products-widget .products-list, .products-listing .products-list').on('mouseover', 'img', function () {
        if($(this).attr('data-over') !== '') {
            $(this).attr('src', $(this).attr('data-over'));
        };
    });  

    $('.products-widget .products-list, .products-listing .products-list').on('mouseout', 'img', function () {
        $(this).attr('src', $(this).attr('data-src'));
    });  
}

function configureImageRolloverSearchSite() {
    $('.search-result .products-list').on('mouseover', 'img', function () {
        if($(this).attr('data-over') !== '') {
            $(this).attr('src', $(this).attr('data-over'));
        };
    });  

    $('.search-result .products-list').on('mouseout', 'img', function () {
            $(this).attr('src', $(this).attr('data-ng-src'));
    });    
}
