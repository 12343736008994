import 'core-js/features/promise';                                      //Promises polyfill
import 'regenerator-runtime/runtime';                                   //Async-await polyfill
import 'whatwg-fetch';                                                  //Fetch polyfill
import { DeviceInformation } from '../classes/device-information';
import { FavoritesService } from '../modules/favorites-service';
import { initializeProductImages } from "../modules/product-images";
import { SocialLoginService } from '../classes/social-login';

import dayjs from 'dayjs';
dayjs.extend(require('dayjs/plugin/utc'));

//Dynamic imports
import(/* webpackChunkName: "datePickerLanguages" */  `lib/datepicker/datepicker-${dynamicData.session.languageCode2Digit}`);

//Global variables
global.dayjs = dayjs;

let socialLoginService = new SocialLoginService();

configureViewStateTroublePrevention();
configureSocialLoginLibraries();

//#region Dom Ready / Load
$(document).ready(function () {
    configurePhoneFields();
    configureToastrOptions();    
    configureAccountingLibrary();
    configureSearch();
    configureMenu();
    configureDataLayerEvents();
    configureLoginSection();
    configureHotkeys();
    showPendingNotifications();
    configureScrolling();
    configureFavorites();
    configureExpress();
    configureSelect2Dropdowns();
    configureInputPlaceholders();        
    configureCookiebotRenewPrompt();
    configurePostbackWorkaround();
});

$(window).on('load', function () {
    configureMenuImagePreload();
    pushVirtualBasketToDatalayer();
    configureLiveChat();
});

//#endregion

//#region Configuration

function configureSocialLoginLibraries() {
    configureGoogleLibrary();
    configureFacebookLibrary();
}

function configureGoogleLibrary() {
    if (dynamicData.modeSettings.isSwissShop) {
        window.onGoogleLibraryLoad = () => {

            google.accounts.id.initialize({
                client_id: '708511173041-cpe5str5284v00rnjgrlej6m4br7ork6.apps.googleusercontent.com',
                callback: socialLoginService.handleGoogleCredentialResponse
            });

            var lang = dynamicData.session.languageCode2Digit;

            if (document.getElementById("g_id_signin_loginForm")) {
                google.accounts.id.renderButton(
                    document.getElementById("g_id_signin_loginForm"),
                        { theme: "outline", size: "large", locale: lang }
                );
            };

            if (document.getElementById("g_id_signin_orderForm")) {
                google.accounts.id.renderButton(
                    document.getElementById("g_id_signin_orderForm"),
                        { theme: "outline", size: "large", locale: lang }
                );
            };

        };
    }
}

function configureFacebookLibrary() {
    $('#fb_signin_registerForm').click(function () {
        socialLoginService.attachFacebook('register-facebook');
    })

    $('#fb_signin_loginForm').click(function () {
        socialLoginService.attachFacebook('signin-facebook');
    })    

    $('#fb_signin_orderForm').click(function () {
        socialLoginService.attachFacebook('signin-facebook');
    })       
}

function configureSearch() {
    $('.search-form input').keyup(function (event) {            
        //Enter clicked
        if (event.keyCode == 13) {
            goToSearchPage();
        }
    })
    $('.search-form button').click(function () {
        goToSearchPage();
    })
}

function configureMenu() {        

    //Preview of AttributeValue infos on hover for non IOS devices
    if (!DeviceInformation.runningOnIOS()) {
        $('#main-nav .categories a').hover(function () {
            var link = $(this);
            showAttributePreview(link);
        })
    }
        
    //Preview of AttributeValue infos on click for big enough touch devices (they usally have no hover)
    if (DeviceInformation.supportsTouch()) {
        $('#main-nav .categories a').click(function (e) {
            if ($(window).width() >= 992) {                    
                var link = $(this);
                showAttributePreview(link);
                e.preventDefault();
                e.stopPropagation();
            }
        });
    }

    function showAttributePreview(link) {
        var productCount = link.data('count');
        var backgroundUrl = link.data('image');
        var title = link.data('title');
        var description = link.data('description');
        var url = link.attr('href');

        //use default image for those not set
        if (backgroundUrl == '')
            backgroundUrl = dynamicData.page.virtualRoot + "media/images/attributes/category-image-default.jpg";

        preloadImage(backgroundUrl);
        $('#main-nav .category-info #product-count').html(productCount);
        $('.category-content .title').html(title);
        $('.category-content p').html(description);
        $('.category-content a').attr('href', url);
        var content = $('#main-nav .category-content');
        if (content.css('visibility') != 'visible')
            $('#main-nav .category-content').css('visibility', 'visible');
    }


    function preloadImage(imageUrl) {
        var img = $("<img />").attr('src', imageUrl)
        .on('load', function () {                                        
                $('#main-nav .category-image').css("background-image", "url(" + imageUrl + ")");
        });
    }

};
function getPageNameFromDataLayer() {
    var result = '';
    if (dataLayer[0] && dataLayer[0].pageType) {
        result = dataLayer[0].pageType;
    }
    return result;
}

function configureDataLayerEvents() {

    //Product widget impressions 
    var productList = [];
    $('.products-widget:visible .products-list .product').each(function (index) {
        var widget = $(this).closest('.products-widget');
        var product = $(this);
        var productId = product.data('id');
        var productName = product.data('nameEn');
        var productPrice = product.find('.price').text();
        var productPosition = widget.find('.product').index(product) + 1;

        var widgetName = widget.find('h2').html();
        var widgetPosition = $('.products-widget').index(widget) + 1;
        var category = dynamicData.session.languageCode2Digit.toUpperCase() + '/Widgets/' + widgetName;
        var pageName = getPageNameFromDataLayer();
        var list = pageName + ' widget ' + widgetPosition;

        productList.push({
            'id': productId, 'name': productName, 'price': productPrice, 'brand': 'Fleurop', 'category': category,
            'list': list, 'position': productPosition
        });
    });
    if (productList.length > 0) {
        dataLayer.push({
            'event': 'productPromotion',
            'ecommerce': {
                'currencyCode': 'CHF',
                'impressions': productList
            }
        })
    }
      
    //Product listings AND widget link clicks, excluding search for now.
    $('.products-widget .products-list, .products-listing .products-list').on("click", "a", function (e) {
        var productList = $(this).closest('.products-list');
        var product = $(this).closest('.product');
        var productId = product.data('id');
        var nameEn = product.data('nameEn');
        var categoryEn = '';
        var list = '';
        var position = 0;
        if (productList.data('listingEn')) {
            //Listing case
            categoryEn = productList.data('listingEn');
            list = 'productListing';
            position = $('.product').index(product);     
            if (Cookiebot.consent.statistics)
                Cookies.set('ProductReferer', dynamicData.enums.enmProductReferer.AttributeListing);
        } else {
            //Widget case
            var widget = $(this).closest('.products-widget');
            var widgetName = widget.find('h2').html();
            var widgetPosition = $('.products-widget').index(widget) + 1;
            categoryEn = dynamicData.session.languageCode2Digit.toUpperCase() + '/Widgets/' + widgetName;                
            var pageName = getPageNameFromDataLayer();
            list = pageName + ' widget ' + widgetPosition;
            position = widget.find('.product').index(product) + 1;      
            if (Cookiebot.consent.statistics)
                Cookies.set('ProductReferer', dynamicData.enums.enmProductReferer.WidgetPage);
            if (Cookiebot.consent.statistics)
                Cookies.set('LastWidgetName', list);
        }
        var price = product.find('.price').text();

        onProductClick(this, {
            'name': nameEn, 'id': productId, 'price': price, 'brand': 'Fleurop',
            'category': categoryEn, 'list': list, 'position': position
        });
    });

    //Slider and hero banner impressions
    var slidesList = [];
    $('#home-slider .owl-item').not('.cloned').each(function (index) {
        var item = $(this).find('.item');
        var position = index + 1;
        var id = 'slider-' + position;
        var name = item.find('h3').html().trim();
        var url = extractUrl(item.css('background-image'));
        slidesList.push({ 'id': id, 'name': name, 'creative': url, 'position': position })
    });
    var bannersList = [];
    $('.hero-banner').each(function (index) {
        var item = $(this);
        var name = item.find('h3').html().trim();
        var position = index + 1;
        var id = 'hero-banner-' + position;
        var url = extractUrl(item.css('background-image'));
        bannersList.push( { 'id': id, 'name': name, 'creative': url, 'position': position } )
    });
    var impressionsList = slidesList.concat(bannersList);
    if (impressionsList.length > 0) {
        dataLayer.push({
            'event': 'bannersLoad',
            'ecommerce': {
                'promoView': {
                    'promotions': impressionsList
                }
            }
        });
    }

    //Hero banner link clicks        
    $('.hero-banner').on('click', 'a', function (e) {
        var item = $(this).closest('.hero-banner');
        var name = item.find('h3').html().trim();
        var position = $('.hero-banner').index(item) + 1;
        var id = 'hero-banner-' + position;
        var url = extractUrl(item.css('background-image'));
        var bannerObj = { 'id': id, 'name': name, 'creative': url, 'position': position };
        onPromoClick(this, bannerObj);
    });

    //Picture gallery hover
    if (!DeviceInformation.supportsTouch()) {
        initializeProductImages();
    }
}

function configureLoginSection() {
    socialLoginService.clearLoginAlert();
        
    if (dynamicData.session.loginStateIsLoggedIn)
        $('#divLoggedIn').toggleClass('d-none');
    else
        $('#divLoggedOut').toggleClass('d-none');

    $('#user-popup,#user-b2b-popup').on('show.bs.modal', function () {
        gtdl_push(2);
        setTimeout(function () {
            $('.tbUserLogin').focus();
        }, 800);            
    })

    if (getQueryStringParameter('showloginpopup') == 'true') {
        if (dynamicData.modeSettings.isB2C)
            $('#user-popup').modal('show');
        else
            $('#user-b2b-popup').modal('show');
    }

    if (getQueryStringParameter('showregistrationpopup') == 'true') {
        if (dynamicData.modeSettings.isB2C)
            $('#user-popup').modal('show').find('.btn-register').click();
        else
            $('#user-b2b-popup').modal('show').find('.btn-register').click();            
    }

    $('#divLoggedIn span').click(function () {
        if (dynamicData.modeSettings.isB2C) {
            location.href = dynamicData.page.virtualRoot + 'myfleurop.aspx';
        } else {
            location.href = dynamicData.page.virtualRoot + 'customercenter.aspx';
        }
    });

    $(".btnUserLogin").click(function (e) {
        e.preventDefault();
        var containerDiv = $(this).closest('.login-content');
        var email = containerDiv.find('.tbUserLogin').val();
        var password = containerDiv.find('.tbUserPassword').val();
        FleuropWebShop.ProfileService.Login(email, password, function (result) {
            socialLoginService.clearLoginAlert();

            if (result == dynamicData.enums.enmMyFleuropLoginStatus.Success) {
                $('#user-popup').modal('hide');
                if (dynamicData.page.codeFileName == 'orderform') {
                    $('#txtSenderPassword:visible').val('');
                    angularOrderFormController.profile.getLoginState();
                    toggleLoginState();
                    dynamicData.session.loginStateIsLoggedIn = true;
                } else {
                    location.href = dynamicData.page.virtualRoot + 'myfleurop.aspx';
                }
                dataLayer.push({ 'event': 'loginManual' });
            } else if (result == dynamicData.enums.enmMyFleuropLoginStatus.Failed) {
                setLoginAlert(dynamicData.textRepository.loginError, 'alert-danger');
                gtdl_push(3);
            } else if (result == dynamicData.enums.enmMyFleuropLoginStatus.NotVerified) {
                setLoginAlert(dynamicData.textRepository.loginNotVerifiedError, 'alert-warning');
                $('.loginAlertPlaceholder a').prop("href", dynamicData.page.virtualRoot + 'handlers/emailverification.aspx?m=' + email);
                gtdl_push(14);
            } else if (result == dynamicData.enums.enmMyFleuropLoginStatus.NoPassword) {
                //Send pw reset mail            
                FleuropWebShop.ProfileService.ResetPassword(email);
                setLoginAlert(dynamicData.textRepository.loginNoPasswordError, 'alert-warning');
            }
        });
    });

    $(".btnB2BLogin").click(function (e) {
        e.preventDefault();

        var containerDiv = $(this).closest('.login-content');
        var email = containerDiv.find('.tbUserLogin').val();
        var password = containerDiv.find('.tbUserPassword').val();
            
        FleuropWebShop.ProfileService.LoginB2B(email, password, function (result) {
            socialLoginService.clearLoginAlert();

            if (result == dynamicData.enums.enmMyFleuropLoginStatus.Success) {
                $('#user-b2b-popup').modal('hide');

                if (dynamicData.page.codeFileName == 'orderform') {
                    location.reload();
                } else {
                    location.href = dynamicData.page.virtualRoot + 'customercenter.aspx';
                }
            } else if (result == dynamicData.enums.enmMyFleuropLoginStatus.NoPassword) {                    
                setLoginAlert(dynamicData.textRepository.loginNoPasswordError, 'alert-warning');

            } else {
                //failed or unverified
                setLoginAlert(dynamicData.textRepository.loginError, 'alert-danger');
            }
        });
    });

    $(".recover-password").click(function () {
        var containerDiv = $(this).closest('.login-content');
        var email = containerDiv.find('.tbUserLogin').val();

        containerDiv.find(".pw-forgot-content").show();
        var reminderEmailField = containerDiv.find(".tbUserLoginReminder");
        reminderEmailField.val(email).focus();            
    });

    $(".btnSendReminder").click(function (e) {
        e.preventDefault();
        var containerDiv = $(this).closest('.login-content');
        socialLoginService.clearLoginAlert();
        var login = containerDiv.find('.tbUserLoginReminder').val();
        FleuropWebShop.ProfileService.SendReminder(login, function (result) {                
            if (result) {
                setLoginAlert(dynamicData.textRepository.loginReminderSent, 'alert-success');
                $(".pw-forgot-content").hide();
            }
            else
                setLoginAlert(dynamicData.textRepository.loginReminderError, 'alert-danger');
        });
    });

    $('.btnB2BSendReminder').click(function (e) {
        e.preventDefault();
        var containerDiv = $(this).closest('.login-content');
        var login = containerDiv.find('.tbUserLoginReminder').val();

        socialLoginService.clearLoginAlert();
        FleuropWebShop.ProfileService.SendReminderB2B(login, function (result) {
            if (result) {
                setLoginAlert(dynamicData.textRepository.loginReminderSent, 'alert-success');
                $(".pw-forgot-content").hide();
            }
            else
                setLoginAlert(dynamicData.textRepository.loginReminderError, 'alert-danger');
        });

    });

    function clearLoginAlert() {
        $('.loginAlertPlaceholder').hide();
    }

    function setLoginAlert(message, alertClass) {
        var loginDiv = $('.loginAlertPlaceholder div');
        loginDiv.prop('class', 'alert ' + alertClass);
        loginDiv.html(message);

        $('.loginAlertPlaceholder').show();
    }

    function toggleLoginState() {            
        $('#divLoggedIn').toggleClass('d-none');
        $('#divLoggedOut').toggleClass('d-none');
    }

    $("a.logout-link").click(function () {
        if (dynamicData.modeSettings.isSwissShop) {
            try { socialLoginService.signOut_Google(); } catch {};
            try { socialLoginService.signOut_Facebook(); } catch {};         
        }
        
        FleuropWebShop.ProfileService.Logout(function () {
            if (dynamicData.page.codeFileName == 'dev')
                location.reload();
            else
                location.href = dynamicData.page.homeLink;
        })
    });

    
    //Render Google Sign-In button for login-form
    $('#divLoggedOut').on('click',function(){
        var lang = dynamicData.session.languageCode2Digit;

        google.accounts.id.renderButton(
            document.getElementById("g_id_signin_loginForm"),
                { theme: "outline", size: "large", locale: lang }
        );
    });

    //Detection of the Google Sign-In process (login or registration) and
    //render Google Sign-In button for registration-form
    $('.account-popup .btn-register').on('click',function(){
        var lang = dynamicData.session.languageCode2Digit;

        SocialLoginService.googleSocialProcess = 'register';

        google.accounts.id.renderButton(
            document.getElementById("g_id_signin_registerForm"),
                { theme: "outline", size: "large", locale: lang }
        );
    });

    $('.account-popup .btn-login').on('click',function(){
        SocialLoginService.googleSocialProcess = 'login';
    });
    
    
    //Apply custom greeting that respects daytime.
    if (dynamicData.page.greeting != '')
        $('#divLoggedIn span').html(dynamicData.page.greeting);
}

//configures keyboard hotkeys, that can be used to access features of the shop.
function configureHotkeys() {
    //a space between two keys represents a sequence, so "g d" means, press g, than d.

    //Go dev
    Mousetrap.bind('g d', function () {
        location.href = dynamicData.page.virtualRoot + 'dev';
    });

    //Go home
    Mousetrap.bind('g h', function () {
        location.href = dynamicData.page.virtualRoot;
    });

    //Go to orderform
    Mousetrap.bind('g o f', function () {
        location.href = dynamicData.page.virtualRoot + 'de/p/das-ist-freddy-echeveria/ids-73397';
    });

    //Go to registration
    Mousetrap.bind('g r', function () {
        if (dynamicData.modeSettings.isB2C)
            location.href = dynamicData.page.virtualRoot + 'registration.aspx';
        else
            location.href = dynamicData.page.virtualRoot + 'registrationb2b.aspx';
    });     

    //Go to help
    Mousetrap.bind('h p', function () {
        location.href = dynamicData.page.virtualRoot + 'help';
    });

    //Copyright can be used as button, currently supported:
    // - doubleclick: go to help page
    // - tripleclick: fill test order
    let copyrightClickCount = 0;
    $('.copyright span').on('click', function () {
        copyrightClickCount++;
        setTimeout(() => {
            if (copyrightClickCount === 2)
                location.href = dynamicData.page.virtualRoot + 'help';
            else if (copyrightClickCount === 3) 
                if (typeof fillTestOrder === 'function')
                    fillTestOrder();
            
            copyrightClickCount = 0;
        }, 500); // 500ms to allow additional clicks

    });


    //Focus search
    Mousetrap.bind('f s', function (e) {
        e.preventDefault();
        $('.search-form input').focus();
    });
}

function configureScrolling() {        
    //Configuration
    $.extend($.scrollTo.defaults, {
        offset: { top: dynamicData.page.scrollingOffsetWithContext },
        duration: 1000
    });
        
    //Feature that allows querystring parameter scrollto on any page                
    var scrollToParam = getQueryStringParameter('scrollto');
    if (scrollToParam != '') {            
        scrollToParam = '#' + scrollToParam;            
        $.scrollTo($(scrollToParam));            
    }
}

function configureFavorites() {

    //Tooltips for products in widgets, listings and on orderform.
    if (!DeviceInformation.supportsTouch()) {
        $('.favorite').tooltip({        
            toggle: "tooltip",
            placement: "left",
            html: true,
            title: `<span class='bold'>${dynamicData.textRepository.favorites}</span><br/>${dynamicData.textRepository.favoritesAdd}`
        });  
    }

    $('.favorite').click(async function () {
        //lists/widgets
        var product = $(this).closest('.product');
        //orderform
        if (product.length == 0)
            product = $(this).closest('.product-main-info');
        var productID = product.data('id');        
        
        if($(this).hasClass('added')) {
            $(this).removeClass('added');
            let count = await FavoritesService.remove(productID);            
            updateFavoriteCount(count)
            
        } else {
            $(this).addClass('added');
            let count = await FavoritesService.add(productID);            
            updateFavoriteCount(count);            

        }

        $('#head-favorite').toggleClass('highlighted')
        setTimeout(function () {
            $('#head-favorite').toggleClass('highlighted')
        }, 500);
    });

    $('#head-favorite').click(function () {
        if ($('#head-favorite span').html() == 0)
            location.href = dynamicData.page.favoritesContentPage;
        else
            location.href = '/favorites';
    });

    function updateFavoriteCount(count) {
        $('#head-favorite span, #favorite-listing-count').html(count);
    }    
}
global.configureFavorites = configureFavorites;

function configureExpress() {
    //Advertisement
    $('.express-offer a').click(function () {
        location.href = dynamicData.modeSettings.expressCatalogLink;
    })

    //Open check popup
    $('.express-available a').click(function () {
        //open popup, fill field and focus
        $('#express-check-popup').modal('show');
        $('#expressDeliveryCity').val($('#txtRecipientZipTown').val());
        $('#expressDeliveryCity').focus();
    })

    //Autocompleter
    $('#expressDeliveryCity').autoComplete({
        source: configureSource(function () { return dynamicData.session.countryRegNr; }),
        delay: 400,
        minChars: 2,
        renderItem: configureRenderItem(),
        onSelect: function (e, term, item) {
            var newRegNr = item.data('regnr');

            if (newRegNr != $('#hiddenRecipientZipTownRegNr').val()) {
                $('#hiddenRecipientZipTownRegNr').val(newRegNr);
                $('#txtRecipientZipTown').val($('#expressDeliveryCity').val());
                //checkDeliveryDate();
            }
        }
    });

    $('#expressDeliveryCity').keyup(function (e) {
        //If region already selected the backspace or delete buttons clear the whole selection
        if (e.keyCode == 46 || e.keyCode == 8) {
            if ($('#hiddenRecipientZipTownRegNr').val() != '') {
                $('#hiddenRecipientZipTownRegNr').val('');
                $('#expressDeliveryCity').val('');
                $('#txtRecipientZipTown').val('');
            }
        } else if (e.keyCode != 9 && e.keyCode != 13 && e.keyCode != 16) { // Not Tab & Enter & Shift                    
            $('#hiddenRecipientZipTownRegNr').val('');
        }
    });

    //Express check
    $('#btnCheckExpressAvailability').click(function () {
        var regNr = $('#hiddenRecipientZipTownRegNr').val();

        clearResult();
        FleuropWebShop.FloristService.GetExpressDetails(regNr, function (result) {
            if (result.ExpressAvailable) {
                var guaranteedDateString = dayjs(result.GuaranteedDeliveryTime).format('DD.MM.YYYY - HH:mm');
                $('#lblGuaranteedDeliveryTime').html(guaranteedDateString);
                $('#expressAvailable').toggleClass('d-none');
            } else {
                $('#expressNotAvailable').toggleClass('d-none');
            }
        })
    });

    function clearResult() {
        $('#expressAvailable').addClass('d-none');
        $('#expressNotAvailable').addClass('d-none');
    }
}

function configureSelect2Dropdowns() {
    // Standard select2 init 
    $('.custom-dropdown').select2({
        placeholder: function () {
            $(this).data('placeholder');
        },
        allowClear: false,
        selectOnClose: true
    });

    // Open Select2 dropdown focus (with tab)
    $(document).on('focus', '.select2-selection.select2-selection--single', function (e) {
        $(this).closest(".select2-container").siblings('select:enabled').select2('open');
    });

    // Open Select2 dropdown focus (with tab); only once controller
    $('select.select2').on('select2:closing', function (e) {
        $(e.target).data("select2").$selection.one('focus focusin', function (e) {
            e.stopPropagation();
        });
    });
}

function configureInputPlaceholders() {
    $('.user-form input').on('focus', function () {
        setInputPlaceholder($(this));
    });
    
    $('.user-form input').on('blur', function () {
        removeInputPlaceholder($(this));
    });    
}

function setInputPlaceholder(input) {
    var placeholder = $(input).attr("placeholder");
    var inputPlaceHolder = 'inputPlaceHolder_'+$(input).attr("id");

    $('#'+inputPlaceHolder).remove();
    $(input).addClass("hidePlaceholder");

    if (placeholder !== undefined) {
        $(input).after('<span class="inputPlaceholder" id="'+inputPlaceHolder+'">'+placeholder+'</span>');
    };    
};

function removeInputPlaceholder(input) {
    var inputPlaceHolder = 'inputPlaceHolder_'+$(input).attr("id");

    $(input).removeClass("hidePlaceholder");
    $('#'+inputPlaceHolder).remove();   
}; 

async function configureValidationEngine(selector = "#aspnetForm") {
    //load language file
    let lang = dynamicData.session.languageCode2Digit;
    await import(/* webpackChunkName: "validationEngineLanguages" */  `validationEngine/jquery.validationEngine-${lang}`);

    let config = {
        //attention! offset needs to be passed in positive to be subtracted
        autoPositionUpdate: true, promptPosition: "bottomLeft:+10,+0", autoHidePrompt: true, autoHideDelay: 8000,
        binded: false, scrollOffset: -1 * dynamicData.page.scrollingOffset, focusFirstField: true
    }

    $(selector).validationEngine(config);
}
global.configureValidationEngine = configureValidationEngine;

function configureZipTownDropdown() {
    var pageSize = 20;
    var zipTownSelector = $('.ziptown-dropdown');

    zipTownSelector.select2({
        allowClear: false,
        language: {
            noResults: function () {
                return dynamicData.modeSettings.dropdownRecipientZipCityNotFoundMessage;
            }
        },
        ajax: {
            url: dynamicData.page.virtualRoot + 'api/regions/get',
            dataType: 'json',
            delay: 50,
            cache: true,

            placeholder: function () {
                $(this).data('placeholder');
            },

            data: function (params) {
                var page = params.page || 1;
                var searchTerm = params.term || '';

                var query = {
                    countryRegNr: dynamicData.session.countryRegNr,
                    searchTerm: searchTerm,
                    page: page
                }

                return query;
            },

            processResults: function (data) {
                var searchResults = data.map(function (item) {
                    var result = {
                        id: item.zipPlaceRegNr,
                        text: item.zip + ' - ' + item.place
                    }

                    return result;
                })

                return {
                    results: searchResults,
                    pagination: {
                        more: (searchResults.length == pageSize ? true : false)
                    }
                }

            }

        },
    })
}
global.configureZipTownDropdown = configureZipTownDropdown;

function configureMenuImagePreload() {
    // Preload Menu default image so it's immediately there
    enquire.register('screen and (min-width: 768px)', function () {
        //Preload default image
        var img = new Image();
        img.src = dynamicData.page.virtualRoot + 'media/images/attributes/category-image-default.jpg';            
    })
}

function configureAccountingLibrary() {
    // Configure accounting library (to work with localized numbers).       
    if (dynamicData.modeSettings.isSwissShop) {
        var thousand = "";
        var decimal = ".";
    } else {
        var thousand = "";
        var decimal = ",";
    }

    accounting.settings = {
        currency: {
            symbol: "€",   // default currency symbol is '$'
            format: "%s%v", // controls output: %s = symbol, %v = value/number (can be object: see below)
            decimal: decimal,  // decimal point separator
            thousand: thousand,  // thousands separator
            precision: 2   // decimal places
        },
        number: {
            precision: 2,  // default precision on numbers is 0
            thousand: thousand,
            decimal: decimal
        }

    }
}

function configureViewStateTroublePrevention() {
    $('#__EVENTVALIDATION').attr('autocomplete', 'off');
}


function configurePhoneFields() {    
    //Allow only the following chars to be entered on phone fields: + and 0-9. 
    $('input[id*="Phone"], input[class*="phone"]').keyup(function () {
        let phoneContent = $(this).val();
        phoneContent = phoneContent.replace(/[^+0-9]/g, '');
        $(this).val(phoneContent);
    });
}

function configureToastrOptions() {
    //Initialize notifications
    toastr.options = {
        "closeButton": true,
        "debug": false,
        "newestOnTop": false,
        "progressBar": false,
        "positionClass": "toast-top-right",
        "preventDuplicates": true,
        "onclick": null,
        "showDuration": "300",
        "hideDuration": "1000",
        "timeOut": "9000",
        "extendedTimeOut": "1000",
        "showEasing": "swing",
        "hideEasing": "linear",
        "showMethod": "fadeIn",
        "hideMethod": "fadeOut"
    }
}

function configureLiveChat() {
    if (!dynamicData.session.transmissionToNonEuCountries) return;

    //LiveChat (www.livechatinc.com) code 
    window.__lc = window.__lc || {};
    window.__lc.license = 8978395;
    window.__lc.group = dynamicData.modeSettings.liveChatGroup;
    window.__lc.params = [
        { name: 'Login.AdrNr', value: dynamicData.session.loginStateAdrNr },
        { name: 'Login.ContactAdrNr', value: dynamicData.session.loginStateContactAdrNr }
    ];
    if (dynamicData.session.loginStateIsLoggedIn)
        window.__lc.params.push({ name: 'Login.Email', value: dynamicData.session.loginStateEmail });

    (function () {
        var lc = document.createElement('script');
        lc.type = 'text/javascript';
        lc.async = true;
        lc.src = ('https:' == document.location.protocol ? 'https://' : 'http://') + 'cdn.livechatinc.com/tracking.js';
        var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(lc, s);
    })();
}

function configureCookiebotRenewPrompt() {
    //open the cookiebot dialog again
    $('.open-consent-popup').click(function () {
        Cookiebot.renew();
    })

    //refresh the page to apply the new settings
    $('.refresh-page').click(function () {
        location.reload();
    })
}

function configurePostbackWorkaround() {
    //CSP fix: handle the postback outside the inline event and suppress the inline event
    //This applies to all links that have an "id"" starting with "lb"" and all elements with the class "postback-override"

    $('a[id^="lb"], .postback-override').on('click', function (event) {
        var clickedItemName = $(this).attr('id').replace('#', '');
        __doPostBack('ctl00$CenterContent$' + clickedItemName, '');
        event.preventDefault();
    });
}

//#endregion

//#region Utilities

function goToSearchPage() {
    var searchTerm = $('.search-form input').val();
    location.href = dynamicData.page.virtualLink + '/search/?q=' + searchTerm;
}

function detachValidationEngine() {
    if ($("#aspnetForm").validationEngine !== undefined)
        $("#aspnetForm").validationEngine('detach');
    return true;
}

function searchInShop(searchFieldID, event) {
    var doSearch = false;

    if (event) {
        //KeyUp case
        if (event.keyCode == 13) {
            doSearch = true;
        }
    } else {
        //Click case
        doSearch = true;
    }

    if (doSearch) {
        var searchTerm = $('#' + searchFieldID).val();
        location.href = dynamicData.page.virtualLink + '/search/?q=' + searchTerm;
    }
}

//#endregion