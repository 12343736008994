import { fetchOnce, fetchRetry } from '../modules/fetch';

export class FavoritesService {

    static async add(productID) {
        var antiForgeryToken = document.getElementById('hiddenAntiForgeryToken').value;
        let response = await fetchOnce(`${dynamicData.page.virtualRoot}api/favorites/add?productID=${productID}`, {
            method: 'POST',
            headers: {
                'X-AntiForgery-Token': antiForgeryToken
            }
        });
        return response.json();
    }

    static async remove(productID) {
        var antiForgeryToken = document.getElementById('hiddenAntiForgeryToken').value;
        let response = await fetchOnce(`${dynamicData.page.virtualRoot}api/favorites/remove?productID=${productID}`, {
            method: 'DELETE',
            headers: {
                'X-AntiForgery-Token': antiForgeryToken
            }
        });
        return response.json();
    }
}
